import { useState } from "react";
import { scroller } from "react-scroll";

import {
  useResumeViewsResumePdfToTextMutation,
  useResumeViewsGetJobsQuery,
  useResumeViewsCreateResumeMutation,
  useResumeViewsGetResumeQuery,
  useResumeViewsGetTagsQuery,
} from "../../store/enhancedResumeApi";
import { useSearchParams } from "react-router-dom";

import React, { useEffect } from "react";
import OrderBy, { SortState } from "../../components/OrderBy";

import { Element } from "react-scroll";
import SkeletonLoader from "../../components/SkeletonLoader";
import { Accordion, Button } from "flowbite-react";
import RadioForm from "../../components/RadioForm";
import ToggleSwitch from "../../components/ToggleSwitch";
import Paginator from "../../components/Paginator";
import FileUpload from "../../components/FileUpload";
import JobPost from "../../components/JobPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { categoryDictionary, formatTag, tagToEmoji, } from "../../utils/tagFormatter";
import FeedbackButton from "../../components/FeedbackButton";

import "../../../css/ResumeToJobs.css";

type HiringPostTime = {
  month: string;
  year: number;
  slug?: string;
};

function ResumeToJobs() {
  const HIRING_POSTS = JSON.parse(
    document.getElementById("hiring_posts")?.textContent ?? "{}"
  ) as HiringPostTime[];
  const TAG_CATEGORIES = ["technology", "arrangement", "location", "role", "job-type"];

  const [selectedHiringPostTime, setSelectedHiringPostTime] = useState<HiringPostTime>(HIRING_POSTS[0]);

  // Get the search parameters from the URL
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize the state variables using the values from the URL search parameters
  const initialResumeUUID = searchParams.get("resumeUUID") || "";

  const [resumeUUID, setResumeUUID] = useState<string>(initialResumeUUID);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [distance, setDistance] = useState("cosine");
  const [orderBy, setOrderBy] = useState<SortState>("ascending");
  const [resumeText, setResumeText] = useState<string>("");

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  const [tagStates, setTagStates] = useState(new Map());


  const clearFilters = () => {
    setSelectedTags([]);
    setTagStates(new Map());
  }

  const handleCheckboxChange = (tag) => {
    setTagStates((prevState) => {
      const newTagStates = new Map(prevState);
      const currentState = newTagStates.get(tag) || 0;
      const newState = (currentState + 2) % 3 - 1; // Rotates between -1, 0, 1
      newTagStates.set(tag, newState);

      // If the state is 0, remove the tag from selectedTags (both the tag and ~tag, if they exist)
      // If the state is 1, remove ~tag (if it exists), and add the tag
      // If the state is -1, remove the tag (if it exists), and add ~tag
      setSelectedTags((prevTags) => {
        let newTags = prevTags.filter((t) => t !== tag && t !== '~' + tag);
        if (newState === 1) {
          newTags.push(tag);
        } else if (newState === -1) {
          newTags.push('~' + tag);
        }
        return newTags;
      });

      return newTagStates;
    });
  };

  const {
    data: resumeData, // The data returned by the query
    error: resumeError, // Any error that occurred while fetching the data
    isLoading: getResumeIsLoading, // Whether the query is currently loading
    isFetching: getResumeIsFetching, // Whether the query is currently fetching in the background
  } = useResumeViewsGetResumeQuery(
    { resumeUuid: resumeUUID }, // Pass the resumeUUID as a parameter to the query
    { skip: resumeUUID === "" } // Optionally, skip the query if resumeUUID is -1
  );

  const handleSelectChange = (event) => {
    const [month, year] = event.target.value.split("-");
    setSelectedHiringPostTime({ month: month, year: year } as HiringPostTime);
  };

  useEffect(() => {
    setResumeText(resumeData?.text || "");
  }, [resumeData]);

  const {
    data: jobsData,
    error: jobsError,
    isLoading: getJobsIsLoading,
    isFetching: getJobsIsFetching,
    refetch,
  } = useResumeViewsGetJobsQuery(
    {
      resumeUuid: resumeUUID,
      page,
      pageSize,
      orderBy: orderBy.toString(),
      month: selectedHiringPostTime.month,
      year: selectedHiringPostTime.year,
      tags: selectedTags.join(","),
    },
    { skip: resumeUUID === "" }
  );

  const [
    resumePdfToText,
    {
      isLoading: pdfToTextIsLoading,
      isError: pdfToTextIsError,
      error: pdfToTextError,
      isSuccess: pdfToTextIsSuccess,
      data: pdfToTextData,
    },
  ] = useResumeViewsResumePdfToTextMutation();

  const [
    createResume,
    {
      isLoading: createResumeIsLoading,
      isError: createResumeIsError,
      error: createResumeError,
      isSuccess: createResumeIsSuccess,
      data: createResumeData,
    },
  ] = useResumeViewsCreateResumeMutation();

  const {
    data: tagsData,
    error: tagsError,
    isLoading: getTagsIsLoading,
    isFetching: getTagsIsFetching,
    refetch: refetchTags,
  } = useResumeViewsGetTagsQuery();

  const [file, setFile] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");

  const handleFileChange = async (event) => {

    let selectedFile = null;
    if (event.target) {
      selectedFile = event.target.files[0];
    } else {
      selectedFile = event[0];
    }

    const maxSize = 2 * 1024 * 1024; // 2MB

    if (selectedFile && selectedFile.size > maxSize) {
      setErrorMsg("File size must be under 2MB");
      setFile(null);
    } else if (selectedFile && selectedFile.type !== "application/pdf") {
      setErrorMsg("File must be a PDF");
      setFile(null);
    } else {
      setErrorMsg(""); // Clear the error message if the file is within the allowed size
      setFile(selectedFile);

      if (selectedFile) {
        // Automatically upload the file if it's within the allowed size
        await handleFileUpload(selectedFile);
      }
    }
  };

  const handleFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await resumePdfToText({ body: formData });

      setResumeText(response.data.text);
    } catch (error) {
      // Handle error, e.g., show an error message
    } finally {
    }
  };

  const handleResumeTextSubmit = async (event) => {
    event.preventDefault();

    if (!resumeText) {
      alert("Please enter resume text to upload");
      return;
    }

    const response = await createResume({
      createResumeIn: { text: resumeText },
    });

    if ("error" in response) {
      alert("Error creating resume: " + response.error);
      return;
    }

    const resumeUUID = response.data.uuid;

    setResumeUUID(resumeUUID);

    setSearchParams({ resumeUUID: resumeUUID.toString() }, { replace: true });

    scrollToSkeletonLoader();
  };

  const scrollToSkeletonLoader = () => {
    scroller.scrollTo("skeletonLoader", {
      duration: 500, // Duration of the scroll animation in milliseconds
      smooth: true, // Enable smooth scrolling
      offset: -150, // Offset from the element's top position (optional)
    });
  };


  return (
    <>
      <div className="min-h-screen">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">

          <div className="my-10">
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-2xl sm:text-4xl font-bold text-gray-900 dark:text-gray-200 ">
                HN Resume to Jobs
              </h1>
            </div>

            <div className="flex flex-row items-center justify-center w-full">
              <h2 className="text-gray-900 dark:text-gray-200 mr-1">
                Find jobs most relevant to your resume for
              </h2>

              <select
                onChange={handleSelectChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-0.5 px-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                {HIRING_POSTS.map((post) => (
                  <option
                    key={`${post.month}-${post.year}`}
                    value={`${post.month}-${post.year}`}
                  >
                    {post.month} {post.year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <h3 className="font-bold my-2 dark:text-gray-200">Upload or Copy/Paste Your Resume</h3>
          <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col w-full sm:w-1/3">
              <FileUpload
                file={file}
                onFileChange={handleFileChange}
                errorMsg={errorMsg}
              />
            </div>


            <div className="inline-block h-100 w-1 mx-5 self-stretch bg-neutral-300 dark:bg-neutral-100 opacity-100 dark:opacity-50"></div>

            <div className="flex flex-col w-full sm:w-2/3 sm:mt-0 mt-10">
              <textarea
                id="resume-text"
                name="resume-text"
                className="block p-2.5 w-full flex-grow text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-h-[250px]"
                placeholder="Enter the text of your resume or keywords for the jobs you're looking for..."
                onChange={(e) => setResumeText(e.target.value)}
                value={resumeText}
              />
            </div>
          </div>


          <div className="flex flex-row mt-5">
            <div className="w-full">
              <form onSubmit={handleResumeTextSubmit} className="w-full h-full">
                <div className="flex justify-center my-10">
                  <Button
                    type="submit"
                    gradientDuoTone="purpleToBlue"
                    onClick={() => {
                      scrollToSkeletonLoader();
                    }}
                  >
                    Search Jobs
                  </Button>

                </div>
              </form>
            </div>
          </div>


          <div className="flex justify-between">

            <p className="text-lg text-gray-900 dark:text-gray-200">
              {jobsData?.total_jobs ?? 0} jobs found
            </p>

            <div className="flex space-x-4">
              <Button
                onClick={onOpenModal}
                gradientDuoTone="purpleToBlue"
                outline
              >
                <span className="font-medium dark:text-gray-200">Filter Jobs</span>
                <FontAwesomeIcon icon={faFilter} className="ml-2 text-gray-600 dark:text-gray-200" />
              </Button>

              <OrderBy
                sortState={[orderBy, setOrderBy]}
                onSortChange={() => {
                  setPage(1);
                }}
              />
            </div>
          </div>

          <Element name="skeletonLoader">
            {(getJobsIsLoading || getJobsIsFetching) && !jobsError && (
              <>
                {[...Array(3)].map((_, index) => (
                  <div key={index}>
                    <hr className="border-gray-300 dark:border-gray-600 my-10" />
                    <SkeletonLoader />
                  </div>
                ))}
              </>
            )}
          </Element>

          <div>
            {!getJobsIsLoading &&
              !jobsError &&
              jobsData &&
              jobsData.jobs.map((job) => (
                <JobPost job={job} />
              ))}

            {!getJobsIsLoading && !jobsError && jobsData && (
              <div className="py-16">
                <Paginator
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  totalJobs={jobsData.total_jobs}
                  scrollToTop={scrollToSkeletonLoader}
                />
              </div>
            )}
          </div>

          <Modal open={open} onClose={onCloseModal}>
            <div className="bg-white dark:bg-slate-800 py-5 px-4 sm:px-10">
              <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-200 mb-2">
                Filter Jobs
              </h3>
              <p className="text-center py-2 dark:text-white">
                ✅ = Included | ❌ = Excluded
              </p>
              <hr className="h-px mb-4 bg-gray-200 border-0 dark:bg-gray-700" />

              <div className="flex flex-col">
                {tagsData && Object.keys(tagsData.tags).length > 0 &&
                  Object.keys(tagsData.tags).map((category) => (
                    <div className="mb-5">
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200 mb-2">
                        {categoryDictionary[category]}
                      </h3>
                      <div className="grid grid-cols-2 gap-1">
                        {tagsData.tags[category] && tagsData.tags[category].map((tag) => {
                          const tagState = tagStates.get(tag) || 0;
                          return (
                            <div className="flex items-center">
                              <button
                                onClick={() => handleCheckboxChange(tag)}
                                className="mr-2"
                              >

                                {tagState === 1 ? '✅' : (tagState === -1 ? '❌' : <input type="checkbox"></input>)}

                                <label
                                  htmlFor={tag}
                                  className="text-gray-900 dark:text-gray-200 pl-2"
                                >
                                  {formatTag(tag)}
                                </label>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
              </div>

              <hr className="h-px mb-4 bg-gray-200 border-0 dark:bg-gray-700" />
              <div className="py-4">
                <div className="flex space-x-4">
                  <Button onClick={onCloseModal}>Apply Filters</Button>
                  <Button
                    color="gray"
                    onClick={() => {
                      clearFilters();
                    }}
                  >
                    Clear All Filters
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>

      </div>

      <FeedbackButton scrollOffset={300} />
    </>
  );
}

export default ResumeToJobs;
