import 'vite/modulepreload-polyfill';
import React from 'react'
import ReactDOM from 'react-dom/client'
import ResumeToJobs from './ResumeToJobs'
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux'
import store from "../../redux/store"

ReactDOM.createRoot(document.getElementById('react__resume_root') as HTMLElement).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ResumeToJobs />
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
)
