import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

type FeedbackButtonProps = {
    scrollOffset: number;
};


const FeedbackButton: React.FC<FeedbackButtonProps> = ({
    scrollOffset,
}) => {
    const [showButton, setShowButton] = useState(false);

    const checkScroll = () => {
        if (!showButton && window.pageYOffset > scrollOffset) {
            setShowButton(true);
        } else if (showButton && window.pageYOffset <= scrollOffset) {
            setShowButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, [showButton]);

    return (
        <a href="https://airtable.com/shrnpYCEqysonPOpl" className="fixed top-2/3 transform translate-x-[50px]  translate-y-1/2 right-0">
            <button type="button" className={`-rotate-90 bg-blue-500 text-white py-2 px-4 rounded-tl-lg rounded-tr-lg hover:bg-blue-600 transition-colors duration-300 transition-opacity  ${showButton ? 'opacity-100' : 'opacity-0'}`}>
                <FontAwesomeIcon icon={faPenToSquare} className="w-4 h-4 ml-0.5 pr-2" />
                <span className="text-base font-semibold">
                    Feedback
                </span>
                <span className="sr-only">Give Feedback</span>
            </button>
        </a>
    )

}

export default FeedbackButton
